.logo {
  display: none;
  font-size: 2rem;
}

@media (min-width: 768px) {
  .logo {
    display: block;
  }
}
